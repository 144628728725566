.challenges-section {
  text-align: center;
  padding: 20px;
  margin-top: 50px;
}

.challenges-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.challenges-section h1{
  margin-bottom: 20px;
}

.challenge-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border-radius: 10px;
  color: white;
  font-size: 1.2em;
  text-decoration: none;
  padding: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.challenge-card .icon {
  font-size: 2em;
  margin-bottom: 10px;
}

.challenge-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Different colors for each card */
.challenge-card:nth-child(1) {
  background-color: #ff6f61;
}

.challenge-card:nth-child(2) {
  background-color: #6b5b95;
}

.challenge-card:nth-child(3) {
  background-color: #88b04b;
}

.challenge-card:nth-child(4) {
  background-color: #f7cac9;
}

.challenge-card:nth-child(5) {
  background-color: #92a8d1;
}

.challenge-card:nth-child(6) {
  background-color: #955251;
}

button.challenge-card {
  border: none;
  cursor: pointer;
}

button.challenge-card:focus {
  outline: none;
}
