/* Navbar.css */

.navbar {
    background-color: #4D4AE8;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, .15), rgba(255, 255, 255, 0));
    box-shadow: 1px 1px 10px gray;
    padding: 15px;
    z-index: 10;
    color: white;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar-logo {
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .brand-name{
    color: white;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .navbar-logo img{
    height: 70px;
    width: 75px;
    padding: 8px;
  }
  
  
  .navbar-links {
    display: flex;
  }
  
  .navbar-links a {
    color: #d6d6d6;
    text-decoration: none;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .navbar-links a:hover {
    text-decoration: underline;
    text-underline-offset: 10px;
    text-decoration-thickness: 2px;
  }
  
  .navbar-toggle button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    color: #ffffff;
    display: none;
  }
  
  .navbar-view-menu button {
    background-color: #4aad4a;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
  }
  
  .navbar-view-menu2 button {
    background-color: #4aad4a;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
  }
  
  .menu-btn {
    text-decoration: none;
    background-color: #4aad4a;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
  }
  
  .navbar-view-menu2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  
  .navbar-view-menu2 button {
    display: none;
  }
  
  @media screen and (max-width: 768px) {
    .navbar-links {
      display: none;
    }
  
    .navbar-toggle button {
      display: block;
    }
  
    .navbar-links-mobile {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 4rem;
      right: 0;
      background-color: #ffffff;
      box-shadow: 1px 1px 10px gray;
      width: 50%;
      text-align: center;
      border-radius: 10px;
      margin: 10px;
      z-index: 10;
    }
  
    .navbar-links-mobile a {
      color: #000000;
      text-decoration: none;
      padding: 1rem;
    }
  
    .navbar-view-menu2 button {
      display: block;
    }
  
    .navbar-view-menu .menu-btn {
      display: none;
    }
  
    .navbar-logo img{
      height: 50px;
      width: 55px;
      padding: 5px;
    }
  
    .navbar{
      padding: 5px 14px; 
    }
    
  }
  




.profile-menu {
  position: relative;
}

.profile-icon {
  color: #fff;
  font-size: 40px;
  cursor: pointer;
}

.logout-popup {
  position: absolute;
  top: 35px;
  right: 0;
  background-color: #707070;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logout-popup button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
}
