.about-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    border: 2px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    margin-top: 50px;
  }
  
  .about-container h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .about-content {
    text-align: left;
  }
  
  .about-content p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #555;
  }
  
  .about-content h2 {
    font-size: 2rem;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #007bff;
  }
  
  .about-content ul {
    list-style-type: none;
    padding: 0;
  }
  
  .about-content ul li {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 10px;
    padding-left: 20px;
    text-indent: -20px;
  }
  
  .about-content ul li strong {
    color: #007bff;
  }
  

  @media(max-width:900px){
    .about-container h1 {
        font-size: 1.5rem;
      }
  }