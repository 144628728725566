.quizes-container {
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .quizes-container h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .quizes-list {
    list-style-type: none;
    padding: 0;
  }
  
  .quiz-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    margin: 10px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(7, 5, 1, 0.1);
  }

  .start-button a{
    text-decoration: none;
    color: white;
  }
  
  .quiz-name {
    font-size: 1.2rem;
    flex: 1;
  }
  
  .quiz-buttons {
    display: flex;
    gap: 10px;
  }
  
  .start-button,
  .details-button {
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  
  .start-button {
    background-color: #28a745;
    color: #fff;
  }
  
  .details-button {
    background-color: #007bff;
    color: #fff;
  }
  
  .start-button:hover {
    background-color: #218838;
  }
  
  .details-button:hover {
    background-color: #0069d9;
  }


  @media(max-width:900px){
    .start-button,
    .details-button {
      padding-left: 10px;
      padding-right: 10px;
      font-size: 0.7rem;
    }

    .quiz-name {
      font-size: 1rem;
      padding: 10px;
    }

      
  .quiz-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    margin: 10px;
    margin-bottom: 20px;
    padding: 20px;
    width: 95%;
  }
}
  