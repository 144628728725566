.quiz-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  margin-top: 100px;
}

.scores {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.opponent-popup {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: #ffeb3b;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  animation: fadeInOut 3s ease-in-out;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.timer {
  font-size: 18px;
  margin-bottom: 20px;
}

.question-container {
  margin-bottom: 20px;
}

.question-container h2 {
  margin-bottom: 10px;
}

.options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.options label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.options input[type="radio"] {
  margin-right: 10px;
}

button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
}

.score {
  font-size: 18px;
  margin-top: 20px;
}



/* 1vs1Quiz.css */

.quiz-container {
  display: flex;
  justify-content: space-between;
}

.quiz-content {
  flex: 1;
}

.leaderboard {
  width: 30%;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.leaderboard h2 {
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.leaderboard ol {
  list-style: none;
  padding: 0;
}

.leaderboard li {
  margin-bottom: 5px;
  font-size: 1rem;
}


.quiz-container {
  text-align: center;
  padding: 20px;
}

.quiz-content {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  width: 80%;
  max-width: 600px;
}

.scores {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.timer {
  margin-bottom: 20px;
}

.question-container {
  margin-bottom: 20px;
}

.options label {
  display: block;
  margin-bottom: 10px;
}

.options input {
  margin-right: 10px;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.opponent-popup {
  background-color: #ffeb3b;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.coins {
  font-weight: bold;
  color: #ffd700; /* Gold color for the coins */
}

.coins svg {
  vertical-align: middle;
  margin-left: 5px;
}
