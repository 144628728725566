.reward-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    border: 2px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    margin-top: 50px;
  }

  .total-coins{
    margin-bottom: 20px;
  }
  
  .reward-container h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .rewards-list {
    display: flex;
    flex-direction: column;
  }
  
  .reward-item {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .reward-icon {
    font-size: 2rem;
    color: #ffcc00;
    margin-right: 20px;
  }
  
  .reward-details h2 {
    font-size: 1.5rem;
    margin: 0;
    color: #007bff;
  }
  
  .reward-details p {
    font-size: 1.2rem;
    margin: 0;
    color: #555;
  }
  
  .redeem-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    transition: background-color 0.3s;
    margin-top: 20px;
  }
  
  .redeem-button:hover {
    background-color: #0056b3;
  }
  
  .redeem-button svg {
    margin-right: 10px;
  }
  
  /* Media Queries for Mobile Responsiveness */
  @media (max-width: 600px) {
    .reward-container {
      padding: 15px;
    }
  
    .reward-container h1 {
      font-size: 2rem;
    }
  
    .reward-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .reward-icon {
      margin-bottom: 10px;
      margin-right: 0;
    }
  
    .reward-details h2 {
      font-size: 1.3rem;
    }
  
    .reward-details p {
      font-size: 1rem;
    }
  
    .redeem-button {
      padding: 8px 16px;
      font-size: 1rem;
    }
  }
  