.contact-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    border: 2px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    margin-top: 50px;
  }
  
  .contact-container h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .contact-info {
    text-align: left;
    margin-bottom: 20px;
  }
  
  .contact-info p {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #555;
  }
  
  .contact-info p strong {
    color: #007bff;
  }
  
  .contact-form {
    text-align: left;
  }
  
  .contact-form h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #007bff;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    font-size: 1.2rem;
    margin-bottom: 5px;
    color: #333;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .form-group textarea {
    resize: vertical;
  }
  
  button[type="submit"] {
    padding: 10px 20px;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    transition: background-color 0.3s;
  }
  
  button[type="submit"]:hover {
    background-color: #0056b3;
  }
  