.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 350px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .modal h2 {
    margin-top: 0;
  }
  
  .modal label {
    display: block;
    margin-top: 15px;
    font-weight: bold;
  }
  
  .modal input[type="text"],
  .modal select {
    width: calc(100% - 20px);
    padding: 5px 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .modal input[type="range"] {
    width: 100%;
    margin-top: 10px;
  }
  
  .modal button {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .modal button:hover {
    background-color: #0056b3;
  }
  
  .modal button + button {
    margin-left: 10px;
  }
  